<template>
    <div>
        <!--span v-if="dataPdf.id != ''">
            <span v-for="data in JSON.parse(dataPdf.rental.json_rental_owners)" :key="data.id">
                <span>{{ data.nome }}</span>
                <span class="ml-3">{{ data.telefone }}</span>
            </span>
        </span-->
        <div class="mt-2 mb-2">
            <v-btn
                depressed
                :dark="params.last_12 == 'true' ? true : false"
                :color="params.last_12 == 'true' ? 'primary' : 'secondary'"
                @click="loadPeriod('true')"
                :class="$css_button"
            >
                Mensalidades mais recentes
            </v-btn>

            <v-btn
                class="ml-3"
                depressed
                :dark="params.last_12 == 'false' ? true : false"
                :color="params.last_12 == 'false' ? 'primary' : 'secondary'"
                :class="$css_button"
                @click="loadPeriod('false')"
            >
                Todas as mensalidades
            </v-btn>
        </div>
        

        <v-card
            class="mx-auto"
            outlined
        >
        <v-data-table
            :headers="headers"
            :items="rental_monthly_fees.data"
            :options.sync="options"
            :server-items-length="rental_monthly_fees.meta && rental_monthly_fees.meta.total"
            class="elevation-1 table-striped caption"
            :footer-props="{
                'items-per-page-text':'Itens por página',
                'items-per-page-options': [6,12],
                'items-per-page-all-text': '',
                'page-text': '{0}-{1} de {2}'
            }"
        >
           <template v-slot:[`item.due_date`]="{ item }">
                <v-chip
                    :color="statusDueDate(item)"
                    label
                >
                    {{ formatDate(item.due_date) }}
                </v-chip>               
            </template>

            <template v-slot:[`item.payment_date`]="{ item }">
                <v-chip
                    v-if="item.payment_date"
                    label
                >
                    {{ formatDate(item.payment_date) }}

                    <span class="ml-3">
                        <v-btn x-small depressed color="blue" dark @click.prevent="loadRentalMonthlyFeePdf('Recibo de pagamento de aluguel',item,object)">
                            recibo
                        </v-btn>
                    </span>
                </v-chip>

                <span v-else>
                    <v-btn x-small depressed color="black" dark @click.prevent="loadRentalMonthlyFeePdf('Recibo de pagamento de aluguel',item,object)">
                        pre recibo
                    </v-btn>
                </span>

            </template>

            <template v-slot:[`item.transfer_owner_date`]="{ item }">
                <v-chip
                    v-if="item.transfer_owner_date"
                    label
                >
                    {{ formatDate(item.transfer_owner_date) }}

                    <span class="ml-3">
                        <v-btn x-small depressed color="blue" dark @click.prevent="loadRentalMonthlyFeePdf('Recibo de repasse de aluguel',item,object)">
                            recibo
                        </v-btn>
                    </span>
                </v-chip>

                <span v-else>
                    <v-btn x-small depressed color="black" dark @click.prevent="loadRentalMonthlyFeePdf('Recibo de repasse de aluguel',item,object)">
                          pre recibo
                    </v-btn>
                </span>
                
            </template>

           
            <template v-slot:[`item.total`]="{ item }">
                   {{ totalTenant(item.json_rental_monthly_fee_values) | toCurrency }}
            </template>

            <template v-slot:[`item.adm`]="{ item }">
                    {{ totalAdm(item.json_rental_monthly_fee_values) | toCurrency }}
            </template>

            <template v-slot:[`item.repasse`]="{ item }">
                    {{ totalOwner(item.json_rental_monthly_fee_values) | toCurrency }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-card-actions class="align-right">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                        <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-8">
                                <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Excluir</span>
                    </v-tooltip>

                     <v-tooltip top>
                        <template v-if="item.update_value && item.update_value == 'Sim'" v-slot:activator="{ on, attrs }">
                            <v-icon
                                 class="ml-6"
                                v-bind="attrs"
                                v-on="on"
                                color="red"
                            >
                                mdi-update
                            </v-icon>
                        </template>
                        <span>Alerta de atualização de aluguel</span>
                    </v-tooltip> 
                </v-card-actions>     
            </template> 
        </v-data-table>
        </v-card>

        <!-- Dialog -->
        <v-dialog 
            v-if="rental_monthly_fee && rental_monthly_fee.id != ''"
            v-model="dialog.edit" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="secondary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none">
                <v-card-title class="text-h5">
                    Contrato
                    <span v-if="object.codigo" class="ml-2">- Código {{object.codigo}} /  Vencimento: {{ rental_monthly_fee.due_date }}</span>
                    <span v-if="object.codigo" class="ml-6 text-subtitle-1"> 
                        <div>
                            <span class="font-weight-medium">Endereço:</span>     
                            {{object.endereco}} 
                            <span v-if="object.numero">, {{ object.numero }}</span>
                            <span v-if="object.apartamento"> APTO {{ object.apartamento }}</span>
                            <span v-if="object.complemento"> {{ object.complemento }}</span>
                        </div>
                    </span>

                    <span v-if="object.codigo" class="ml-6 text-subtitle-1">                         
                        <div>
                            <span class="font-weight-medium">Proprietários:</span>
                            <span v-if="object.json_rental_owners && object.json_rental_owners.length > 0">
                                <span v-for="(cl, index) in object.json_rental_owners" :key="cl.id">
                                    {{ cl.nome }}
                                    <span v-if="(index + 1) < object.json_rental_owners.length">, </span>
                                </span>
                            </span>
                        </div>
                    </span>                        

                    <span v-if="object.codigo" class="ml-6 text-subtitle-1">     
                        <div>
                            <span class="font-weight-medium">Locatários:</span> 
                            <span v-if="object.json_rental_tenants && object.json_rental_tenants.length > 0">
                                <span v-for="(cl, index) in object.json_rental_tenants" :key="cl.id">
                                    {{ cl.nome }}
                                    <span v-if="(index + 1) < object.json_rental_tenants.length">, </span>
                                </span>
                            </span>
                        </div>

                    </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog('edit')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <edit-rental-monthly-fee-component :object="rental_monthly_fee" @callLoadPromisseRentalMonthlyFees="loadPromisseRentalMonthlyFees"></edit-rental-monthly-fee-component>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->

    </div>
</template> 

<script>
import EditRentalMonthlyFeeComponent from './EditRentalMonthlyFeeComponent.vue'

export default {
    components: {
        EditRentalMonthlyFeeComponent
    },

    created () {
        this.loadPromisseRentalMonthlyFees()
    },

    props: {
        object: {},
    }, 

    watch: {
        object () {   
            this.rental_monthly_fees = []
        },

        options: {
            handler() {
                if(this.options.page >= 1){
                    this.loadPromisseRentalMonthlyFees();
                }   
            },
        },
    },

    data () {
        return {
            item: {},

            dataPdf: {
                id: '',
                rental: {
                    json_rental_owners: ''
                }
            },

            dialog: {
                edit: false,
            },

            rental_monthly_fees: [],

            options: {
                page: 1,
                itemsPerPage: 12,
            },

            headers: [
                { text: 'DT VENC.', value: 'due_date' , sortable: false},
                { text: 'DT PGTO.', value: 'payment_date' , sortable: false},
                { text: 'DT REPASE', value: 'transfer_owner_date' , sortable: false},
                { text: 'TOTAL R$', value: 'total' , sortable: false},
                { text: 'ADM + CREDITOS R$', value: 'adm' , sortable: false},
                { text: 'REPASSE R$', value: 'repasse' , sortable: false},
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            params: {
                rental_id: '',
                page: '',
                last_12: 'true'
            },

            rental_monthly_fee: {}
        }    
    },

    methods: {
        loadPeriod( param ){
            if(param == 'true'){
                this.params.last_12 = 'true'
            } else {
                this.params.last_12 = 'false'
            }
            this.setOptions()
            this.loadPromisseRentalMonthlyFees()
        },

        setOptions(){
            this.options.page = 1
            this.options.itemsPerPage = 12
        },


        loadPromisseRentalMonthlyFees(){   
            this.params.rental_id = this.object.id 
            this.params.page = this.options.page
            
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$store.dispatch('loadPromisseRentalMonthlyFees', this.params)
                        .then((response) => {
                            this.rental_monthly_fees = response
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
            
        },

        loadItem(id){
            this.$emit('callLoadItem', id)
        },

        loadRentalMonthlyFeePdf(title, object, rent){
            const params = {}
            params.item_id = object.id
            params.title = title
            params.file_name = 'codigo ' + rent.codigo + ' - ' + params.title + ' - ' + object.due_date + '.pdf'

            this.$store.dispatch('loadRentalMonthlyFeePdf', params) 
        },

        editItem(id){

            this.rental_monthly_fee = ''
            
            this.$store.dispatch('loadRentalMonthlyFee', id)
                        .then((response) => {
                            this.rental_monthly_fee = response.data
                            this.rental_monthly_fee.due_date = this.formatDate(this.rental_monthly_fee.due_date)
                            this.rental_monthly_fee.payment_date = this.formatDate(this.rental_monthly_fee.payment_date)
                            this.rental_monthly_fee.transfer_owner_date = this.formatDate(this.rental_monthly_fee.transfer_owner_date)
                            this.dialog.edit = true
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
            
        },

        openCloseDialog(dialog){
            if(dialog == 'edit') {
                this.dialog.edit = !this.dialog.edit
                if(this.dialog.edit == false){
                    this.loadPromisseRentalMonthlyFees()
                }
            } 

        },
       
        confirmDestroy(item) {
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.$store.dispatch('destroyRentalMonthlyFee', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Delete')
                            this.loadPromisseRentalMonthlyFees()
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },
    },
}
</script>