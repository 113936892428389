<template>
    <div>
        <v-row dense>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-btn
                    depressed
                    dark
                    color="blue-grey darken-1"
                    @click="editContext('adicionar_mensalidades')"
                    :class="$css_button"
                >
                    Adicionar mensalidades
                </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-btn
                    depressed
                    dark
                    color="blue-grey darken-1"
                    @click="editContext('adicionar_encargos_descontos')"
                    :class="$css_button"
                >
                    Adicionar encargos e descontos
                </v-btn>
            </v-col>    
        </v-row>

        <!-- contexto  -->
        <v-dialog
            v-model="dialog"
            width="90%"
        >
            <v-card
                class="pa-2"
                min-height="300"
            >
                <div>
                    <mass-store-monthly-fee-component v-if="context == 'adicionar_mensalidades'" :object="object"></mass-store-monthly-fee-component>
                    <mass-store-value-component v-if="context == 'adicionar_encargos_descontos'" :object="object"></mass-store-value-component>
                </div>

                <div class="text-right ma-4">
                    <v-btn
                        depressed    
                        :class="$css_button_large"
                        color="primary"
                        @click="openCloseDialog()"
                    >
                        Fechar
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- fim contexto  -->

    </div>
</template>

<script>
import MassStoreMonthlyFeeComponent from './MassStoreMonthlyFeeComponent.vue'
import MassStoreValueComponent from './MassStoreValueComponent.vue'

export default {
    components: {
        MassStoreMonthlyFeeComponent,
        MassStoreValueComponent
    },

    props: {
        object: {
            require: true,
        },
    }, 

    data() {
        return {
            context: '',
            dialog: false,
        }
    },

    methods: {
        openCloseDialog(){
            this.dialog = !this.dialog

            if(!this.dialog) this.context = ''
        },

        editContext(context){
            this.context = context
            this.dialog = true
        },
    }
}
</script>